<template>
  <div class="page">
    <el-main>
      <el-container>
        <el-row>
          <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form :inline="true">
              <el-form-item>
                  <el-select v-model="checkServiceItem" multiple filterable collapse-tags placeholder="请选择服务项目" style="width: 220px" @change="SearchPage()" size="medium">
                      <el-option v-for="(item, i) in checkServiceItemData" :key="i" :label="item.ItemName" :value="item.Id"></el-option>
                  </el-select>
              </el-form-item>
              <el-input
                v-model="keyword"
                placeholder="请输入关键字查询"
                class="input-with-select"
                style="width: 300px; margin-right: 10px"
                size="medium"
              >
                <template #prepend>
                  <el-select
                    v-model="checkInput"
                    multiple
                    collapse-tags
                    placeholder="请选择"
                    style="width: 120px"
                  >
                    <el-option
                      v-for="(item, i) in dataTableSearch"
                      :key="i"
                      :label="item.label"
                      :value="item.attr"
                    ></el-option>
                  </el-select>
                </template>
              </el-input>
              <slot name="searchSlot"></slot>
              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  size="medium"
                  @click="SearchPage()"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-download"
                  size="medium"
                  v-has="'ExportExcel'"
                  @click="ExportExcel()"
                  >导出项目办理记录</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-popover placement="bottom" :width="400" trigger="click">
                  <template #reference>
                    <el-button size="medium"
                      ><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button
                    >
                  </template>
                  <el-checkbox-group v-model="dataTableSearchList">
                    <div v-for="(item, i) in dataTableList" :key="i">
                      <el-checkbox
                        v-if="item.visible != false"
                        :label="item"
                        checked
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                      <el-checkbox
                        v-else
                        :label="item"
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                    </div>
                  </el-checkbox-group>
                  <el-button
                    size="small"
                    type="text"
                    @click="filterFunHandle('allchecked')"
                    >全选</el-button
                  >
                  <el-button size="small" type="text" @click="filterFunHandle('cancel')"
                    >取消全选</el-button
                  >
                </el-popover>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-table
              size="small"
              border
              ref="multipleTable"
              :data="dataTable"
              style="width: 99%"
              max-height="700"
              :height="tableHeight"
              :fit="true"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                fixed="left"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                header-align="center"
                align="center"
                width="150"
              >
                <template #default="scope">
                  <el-popconfirm 
                    confirmButtonText="确定"
                    cancelButtonText="取消"
                    icon="el-icon-info"
                    iconColor="red"
                    title="确定办理退款吗？"
                    @confirm="rfund(scope.row.Id)"
                  >
                    <template #reference>
                      <el-button type="danger" v-show="scope.row.IsPay&&!scope.row.IsRfund&&scope.row.Source==1" v-has="'Rfund'" size="mini"
                        >退款</el-button
                      >
                    </template>
                  </el-popconfirm>
                  <el-popconfirm 
                    confirmButtonText="确定"
                    cancelButtonText="取消"
                    icon="el-icon-info"
                    iconColor="red"
                    title="确定删除吗？"
                    @confirm="delete(scope.row.Id)"
                  >
                    <template #reference>
                      <el-button type="danger" v-show="scope.row.Source==0||!(scope.row.IsPay&&!scope.row.IsRfund&&scope.row.Source==1)" v-has="'FakeDelete'" size="mini"
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(col, index) in dataTableSearchList"
                :key="index"
                sortable
                header-align="center"
                align="center"
                :prop="col.attr"
                :label="col.label"
              >
                <template #default="scope">
                  <el-image
                    v-if="col.type == 'img'"
                    :src="scope.row[col.attr]"
                    :preview-src-list="[scope.row[col.attr]]"
                    fit="fill"
                    style="width: 40px; height: 40px"
                  ></el-image>
                  <el-tag size="medium" v-else-if="col.type == 'enum'">
                    {{ col.formatters(scope.row[col.attr]) }}
                  </el-tag>

                  <label v-else>{{ scope.row[col.attr] }}</label>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <paging
              :currentPage="currentPage"
              :pagesize="pageSize"
              :total="total"
              @sizeChange="handleSizeChange"
              @currentChange="handleCurrentChange"
              :pagesizes="pageSizes"
            />
          </el-col>
        </el-row>
      </el-container>
    </el-main>
  </div>
</template>
<script>
export default {
  name: "serviceItemOrderRecord",
  components: {},
  data() {
    return {
      checkServiceItem: "",
      checkServiceItemData: [],
      serviceItemId: 0,
      total: 0, //数据总条数
      pageSize: 10, //当前显示数据数量
      pageSizes: [10, 20, 50, 100],
      currentPage: 1, //分页当前页
      keyword: "", //搜索输入关键字
      checkInput: "", //选择所需要查询的内容
      dataConfig: [
        { label: "Id", attr: "Id", isInput: false, isDisplay: false },
        { label: "项目名称", attr: "ItemName", isSearch: true },
        { label: "班级", attr: "ClassName", isSearch: true },
        { label: "姓名", attr: "StudentName", isSearch: true },
        { label: "订单号", attr: "OrderNo", isSearch: true },
        { label: "金额（元）", attr: "OrderMoney" },
        { label: "订单时间", attr: "OrderTime" },
        {
          label: "是否支付",
          attr: "IsPay",
          type: "enum",
          formatters: function (val) {
            return val ? "是" : "否";
          },
        },
        { label: "支付单号", attr: "PayOrderNo", isSearch: true },
        { label: "支付时间", attr: "PayTime" },
        { label: "OpenId", attr: "PayOpenId", isSearch: true },
        { label: "账号", attr: "PayPhone", isSearch: true },
        {
          label: "来源",
          attr: "Source",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "系统";
                break;
              case 1:
                result = "微信";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "是否退款",
          attr: "IsRfund",
          type: "enum",
          formatters: function (val) {
            return val ? "是" : "否";
          },
        },
        { label: "备注", attr: "RemarkInfo", isInput: false, visible: false },
        { label: "创建时间", attr: "CreatedTime", isInput: false, visible: false },
        { label: "更新时间", attr: "UpdatedTime", isInput: false, visible: false },
      ],
      dataTableSearch: [], //搜索信息
      dataTableList: [], //显示列信息
      dataTableSearchList: [], //列筛选信息
      dataTable: [], //显示数据
      multipleSelection: [], //选中数据
      tableHeight: 200, //table高度
    };
  },
  mounted() {
    const that = this;
    that.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      //设置table位置
      let heightStyle = window.innerHeight - 230;
      that.tableHeight = heightStyle;

      //窗口大小改变时，table 高度设置
      window.onresize = () => {
        return (() => {
          let heightStyle = window.innerHeight - 230;
          that.tableHeight = heightStyle;
        })();
      };
    });
    that.initCheckServiceItemList();
    that.SearchPage();
    that.dataTableSearch = that.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
    that.dataTableList = that.dataConfig.filter((it) => it.isDisplay != false); //可显示列
  },
  methods: {
    //初始化服务项目选择数据
    initCheckServiceItemList() {
        var that = this;
        that.$API.ServiceItem.GetAllServiceItem.get().then((res) => {
            that.checkServiceItemData = res;
        });
    },
    //页面搜索查询
    SearchPage() {
      let that = this;
      let json = {
        searchValue: "",
        pageNo: that.currentPage,
        pageSize: that.pageSize,
        searchBeginTime: that.searchStartTime,
        searchEndTime: that.searchEndTime,
        sortField: "",
        sortOrder: "",
        descStr: "",
        ItemIds:that.checkServiceItem,
      };
      if (that.checkInput.length > 0 && that.keyword != "") {
        var searchParameters = [],
          input = that.keyword;
        for (let index = 0; index < that.checkInput.length; index++) {
          const element = that.checkInput[index];
          var searchParametersItem = {
            field: element,
            op: 8,
            value: input,
            orGroup: "",
          };
          searchParameters.push(searchParametersItem);
        }
        json.searchParameters = searchParameters;
      }

      that.$API.ServiceItemOrderRecord.QueryByPage.post(json).then((res) => {
        if (res) {
          that.total = res.TotalRows;
          that.pagesize = res.PageSize;
          that.currentPage = res.PageNo;
          that.dataTable = res.Rows;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.SearchPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.SearchPage();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 列筛选
    filterFunHandle(type, currentItem, index) {
      if (type === "allchecked") {
        // 全选
        this.dataTableSearchList = this.dataConfig.filter((it) => it.isDisplay != false);
      } else if (type === "cancel") {
        // 取消全选
        this.dataTableSearchList = []; // 复选框置为空，全部不选择
      }
    },
    rfund(id) {
      var that = this;
      that.$API.ServiceItemOrderRecord.Rfund.post(id).then((res) => {
        if (res.Success) {
          that.$message.success(res.Message);
          that.SearchPage();
        }
      });
    },
    delete(id) {
      var that = this;
      that.$API.ServiceItemOrderRecord.FakeDelete.delete([id]).then((res) => {
        if (res.Success) {
          that.$message.success(res.Message);
          that.SearchPage();
        }
      });
    },
    //导出项目办理记录
    ExportExcel(){
      var that = this;
      let json = {
        searchValue: "",
        pageNo: that.currentPage,
        pageSize: that.pageSize,
        searchBeginTime: that.searchStartTime,
        searchEndTime: that.searchEndTime,
        sortField: "",
        sortOrder: "",
        descStr: "",
        ItemIds:that.checkServiceItem,
      };
      that.$API.ServiceItemOrderRecord.ExportExcel.post(json);
    },
    operationSuccess() {
      //操作成功后，关闭弹出框，刷新数据显示
      this.serviceItemId = 0;
      this.SearchPage();
    },
    handleClose(done) {
      var that = this;
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.page {
  margin: 5px;
  padding: 10px;
  min-height: 840px;
  width: 95%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  width: auto;
}
</style>
